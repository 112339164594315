























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({})
export default class PoolCountdown extends Vue {
  @Prop({ default: {} }) countdown!: any
  @Prop({ default: false }) isFilledPool!: boolean
}
